<template>
  <div class="login">
    <div class="login-box">
      <div class="white-l-box big">
        <div>
          <img src="/assets/img/logo-big.png" alt="" class="d-inline-block" />
          <h1 class="d-inline-block bold-19 text-gray mt-2 mb-4 ms-2">
            Prodyflow
          </h1>
        </div>
        <h2 class="bold-24 text-black mt-3">First steps</h2>
        <h3 class="bold-16 text-black mt-3">Languages</h3>
        <div class=""></div>
        <h3 class="bold-16 text-black mt-3">Countries</h3>
        <h3 class="bold-16 text-black mt-3">Currencies</h3>
        <h3 class="bold-16 text-black mt-3">Company</h3>
        <h3 class="bold-16 text-black mt-3">Warehouse</h3>
        <h3 class="bold-16 text-black mt-3">Delivery</h3>
        <h3 class="bold-16 text-black mt-3">Invoice</h3>
        <h3 class="bold-16 text-black mt-3">Payment</h3>
        <h3 class="bold-16 text-black mt-3">Webshop</h3>
        <h3 class="bold-16 text-black mt-3">Shop</h3>
      </div>
    </div>
  </div>
</template>

<script>
//import tenant from "../../modules/tenant";

export default {
  name: "FirstSteps",
  data() {
    return {};
  },
  methods: {},
};
</script>
